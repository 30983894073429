<template>
    <div>
        <v-footer app class="text-center">
            <v-row class="d-flex justify-center">
                <v-col cols="auto">
                    2024 copyrights. 정보책임보호자
                </v-col>
            </v-row>
        </v-footer>
    </div>
</template>

<script>
export default{};
</script>